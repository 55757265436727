import { makeStyles } from '@material-ui/core';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import content from '../content/withdrawal/page.json';
import FiraSans from '../fonts/Fira_Sans/firaSans';
import Colors from '../styles/colors';
import Dimensions, { narrowWidth } from '../styles/dimensions';

const Withdrawal = () => {
	const classes = useClasses();

	return (
		<Layout>
			<SEO title={content.title} />

			<div className={classes.page}>
				<div className={classes.container}>
					<h1 className={classes.header}>{content.title}</h1>

					<p>{content.description}</p>

					<section>
						<div>
							<h3 className={classes.subheader}>{content.withdrawal.title}</h3>
							<p>{content.withdrawal.description}</p>
						</div>

						<div>
							<h4 className={classes.subheader}>{content.withdrawal.consequences.title}</h4>
							<p>{content.withdrawal.consequences.description}</p>
						</div>

						<div>
							<h4 className={classes.subheader}>{content.withdrawal.model.title}</h4>
							<p>{content.withdrawal.model.description}</p>
							<ul>
								{content.withdrawal.model.listItems.map((item, key) => (
									<li key={key}>{item}</li>
								))}
							</ul>
						</div>
					</section>

                    <div className={classes.remarkContainer}>
                        <p>{content.remark}</p>
                    </div>
				</div>
			</div>
		</Layout>
	);
};

export default Withdrawal;

const useClasses = makeStyles({
	page: {
		backgroundColor: Colors.offerBackgroundBlack,
		color: Colors.whiteLightlyDimmed,
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.regularText.fontSize,
		lineHeight: Dimensions.regularText.lineHeight,
		paddingTop: '3.5em',
		paddingBottom: '5.75em',
		paddingLeft: '0.8em',
		paddingRight: '0.8em',
		whiteSpace: 'pre-wrap',
		minHeight: 'calc(100vh - 9.2em)',
		boxSizing: 'border-box',
	},
	container: {
		maxWidth: narrowWidth,
		margin: '0 auto',
	},
	header: {
		fontSize: Dimensions.largeTitle.fontSize,
		lineHeight: Dimensions.largeTitle.lineHeight,
		color: Colors.white,
		marginTop: 0,
		marginBottom: '0.5em',
		'@media (max-width: 599px)': {
			fontSize: Dimensions.regularTitle.fontSize,
			lineHeight: Dimensions.regularTitle.lineHeight,
		},
	},
    subheader: {
		color: Colors.white,
		marginTop: '1.25em',
		marginBottom: '0.625em',
		'@media (max-width: 599px)': {
			fontSize: Dimensions.largeText.fontSize,
			lineHeight: Dimensions.largeText.lineHeight,
		},
	},
    remarkContainer: {
        marginTop: '4em',
    },
});
